<template>
  <Dialog>
    <RegistrationForm
      v-if="!success"
      :is-in-checkout="isInCheckout"
      @success="async () => await onSuccess()"
    />

    <SuccessView
      v-else
      headline="registration.success.headline"
      sub-headline="registration.success.subHeadline"
      :texts="['registration.success.text']"
      image="@/assets/images/minilu/registration-success.svg"
      cta="registration.success.cta"
      @click-cta="() => dialogStore.closeDialog()"
    />
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/global/dialogFrame.vue';
import RegistrationForm from '~/components/form/registration/registrationForm/minilu/registrationForm.vue';
import SuccessView from '~/components/formFields/components/successView/minilu/successView.vue';
import useRegistration from '../useRegistration';

const { onSuccess, success, dialogStore, isInCheckout } = useRegistration();
</script>
